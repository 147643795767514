import { APP_CORE_PATHS, USER_PROFILE_PATHS } from 'components/routes/paths';

export const INITIAL_COORDINATES_STATE = {
	top: 0,
	left: 0,
	width: 0,
	height: 0
};

export const INITIAL_STATE = {
	isPlayerOpen: false,
	isFloatMode: false,
	isPageLoaded: false,
	coordinates: INITIAL_COORDINATES_STATE
};

export const ELEMENT_INTERVAL_TIME = 200;
export const SPINNER_INTERVAL_TIME = 100;
export const RESIZE_THROTTLE_TIME = 100;

export const $ = (el) => document.getElementById(el);

export const getElementCoordinates = (el) => {
	const rect = el.getBoundingClientRect();
	const scrollLeft = document.documentElement.scrollLeft;
	const scrollTop = document.documentElement.scrollTop;

	const top = rect.top + scrollTop;
	const left = rect.left + scrollLeft;

	return {
		top,
		left,
		width: rect.width,
		height: rect.height
	};
};

const WATCH = 'watch';
const { EPG, PROGRAM_DETAILS } = APP_CORE_PATHS;
const { PARENTAL_CONTROL } = USER_PROFILE_PATHS;

const FLOAT_PLAYER_BLOCKED_PATHS = [EPG, PROGRAM_DETAILS, PARENTAL_CONTROL];

export const checkIsPlayerDisabled = (currentPath) => {
	const isFloatingWidgetDisabled =
		process.env.REACT_APP_IS_FLOATING_WIDGET_DISABLED === 'true';
	const notPlayerPath = !currentPath.includes(WATCH);
	const areBlockedPaths = FLOAT_PLAYER_BLOCKED_PATHS.some((path) =>
		currentPath.includes(path)
	);
	return isFloatingWidgetDisabled ? notPlayerPath : areBlockedPaths;
};
